<template>
  <div class="my-box roomPage">
    <el-row class="maxH">
      <!-- :xs="20" :sm="20" :md="20" :lg="21" :xl="22"  -->
      <el-col class="homeContent">
        <!-- 功能区域 -->
        <el-row style="margin-bottom: 5px">
          <el-card shadow="always">
            <el-form
              label-width="70px"
              class="justifyName topS"
              :model="seekData"
              ref="seekData"
            >
              <el-row :gutter="20">
                <!-- 品牌的选择器 -->
                <el-col :span="3">
                  <el-form-item label="品牌">
                    <el-select
                      v-model="seekData.brandId"
                      @change="selectOnes"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in brandSelectData"
                        :key="index"
                        :label="item.brandName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 门店的选择器 -->
                <el-col :span="6">
                  <el-form-item label="门店">
                    <el-select
                      v-model="seekData.hotelId"
                      @change="selectHotel"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in hotelSelectData"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="3">
                  <el-form-item label="建筑" prop="buildingId">
                    <el-select
                      v-model="seekData.buildingId"
                      @change="selectBuilding"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in buildingSelectData"
                        :key="index"
                        :label="item.buildingName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="3">
                  <el-form-item label="楼层" prop="floorId">
                    <el-select
                      v-model="seekData.floorId"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in floorSelectData"
                        :key="index"
                        :label="item.floorName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label-width="0px">
                    <div class="reset-button">
                      <el-button
                        plain
                        class="my-icont"
                        @click="foldData = !foldData"
                      >
                        <div v-if="foldData">
                          收起
                          <i class="el-icon-arrow-up"></i>
                        </div>
                        <div v-else>
                          展开
                          <i class="el-icon-arrow-down"></i>
                        </div>
                      </el-button>
                      <el-button type="primary" @click="handleSearch"
                        >查询</el-button
                      >
                      <el-button @click="reset">重置</el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label label-width="20px">
                    <div class="reset-button">
                      <el-button
                        @click="closeTips"
                        v-if="tip"
                        class
                        type="danger"
                        style="width: 150px"
                        >关闭语音</el-button
                      >
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-show="foldData">
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="门磁状态" prop="doorWarning">
                      <el-select
                        v-model="seekData.doorWarning"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in mcSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="网络状态" prop="basicstatus">
                      <el-select
                        v-model="seekData.basicstatus"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in isConnectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="3">
                    <el-form-item label="温度状态" prop="temStatus">
                      <el-select
                        v-model="seekData.temStatus"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in temperatureSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="SOS状态" prop="sos">
                      <el-select
                        v-model="seekData.sos"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in sosSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="房卡身份" prop="identity">
                      <el-select
                        v-model="seekData.identity"
                        clearable
                        multiple
                        collapse-tags
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in identitySelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="退房状态" prop="checkout">
                      <el-select
                        v-model="seekData.checkout"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in outSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="3">
                    <el-form-item label="服务请求" prop="status">
                      <el-select
                        v-model="seekData.status"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in RTSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="出租情况" prop="rent">
                      <el-select
                        v-model="seekData.rent"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in rentSelectData"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="房间类型" prop="roomTypeId">
                      <el-select
                        v-model="seekData.roomTypeId"
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in roomTypeSelectData"
                          :key="index"
                          :label="item.typeName"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </el-card>
        </el-row>
        <!-- 内容区域 -->
        <el-row v-loading="loading" class="building-info">
          <div class="top-statics">
            <el-badge :value="totalRoomNum" class="badge" type="primary">
              <el-tag type="primary">客房总数</el-tag>
            </el-badge>
            <el-badge :value="totalRoomOfflineNum" class="badge">
              <el-tag type="danger">离线总数</el-tag>
            </el-badge>
          </div>
          <el-card shadow="always">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item
                v-for="(item, index) in tableData"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  <div
                    class="building"
                    style="
                      display: inline-flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../../../static/images/icon/buildingName.png"
                      style="margin-right: 5px"
                    />
                    <span>{{ item.buildingName }}</span>
                  </div>
                  <div
                    :ref="`floorId${index}`"
                    :id="`floorId${item.floorId}`"
                    class="floor"
                    style="
                      display: inline-flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <img
                      src="../../../../static/images/icon/floor.png"
                      style="margin-right: 5px; margin-left: 5px"
                    />
                    <span>{{ item.floorName }}</span>
                  </div>
                  <div class="room-num">
                    <el-tag type="success">在线</el-tag>
                    <el-badge :value="item.floorRoomOnlineNum" class="badge" type="success">
                    </el-badge>
                  </div>
                  <div class="room-offline">
                    <el-tag type="danger">离线</el-tag>
                    <el-badge :value="item.floorRoomOfflineNum" class="badge" type="danger">
                    </el-badge>
                  </div>
                </template>

                <el-card
                  shadow="hover"
                  class="always-shadow"
                  @click.native="openDialog(item1)"
                  v-for="(item1, index) in item.roomStatusRealtimeList"
                  :key="index"
                  :class="{
                    rentBackground: item1.rent == 'rented',
                    noBackground: item1.rent != 'rented',
                    w2: collapse,
                  }"
                >
                  <div class="room_item">
                    <span class="room_num">
                      <span>{{ item1.roomNumber }}</span>
                      <div class="sosImgBar">
                        <img
                          class="sosImg"
                          src="../../../../static/images/icon/i_sos.png"
                          v-if="
                            item1.sos == 'true' && item1.basicstatus == 'on'
                          "
                        />
                      </div>
                    </span>
                    <div v-if="item1.hotelUsable == 'off'" class="unconnected">
                      <el-button size="mini" title="已停用">
                        <img
                          src="../../../../static/images/icon/disabledRoom.png"
                        />
                      </el-button>
                    </div>
                    <div
                      v-else-if="item1.basicstatus != 'on'"
                      class="unconnected"
                    >
                      <el-button size="mini" title="离线">
                        <img src="../../../../static/images/icon/link.png" />
                      </el-button>
                    </div>
                    <div v-else>
                      <div class="room_info" v-show="item1.basicstatus == 'on'">
                        <!-- <el-button size="mini" :title="item1.title">
                      <img :src="item1.img" class="room_status">
                      </el-button>-->
                        <el-button
                          size="mini"
                          title="总裁卡"
                          v-if="item1.identity == 'president'"
                        >
                          <img
                            src="../../../../static/images/icon/i_identity6.png"
                            class="room_status"
                          />
                        </el-button>
                        <el-button
                          size="mini"
                          title="管理卡"
                          v-else-if="item1.identity == 'manage'"
                        >
                          <img
                            src="../../../../static/images/icon/i_identity6.png"
                            class="room_status"
                          />
                        </el-button>
                        <el-button
                          size="mini"
                          title="服务员卡"
                          v-else-if="item1.identity == 'service'"
                        >
                          <img
                            src="../../../../static/images/icon/i_identity8.png"
                            class="room_status"
                          />
                        </el-button>
                        <el-button
                          size="mini"
                          title="客人卡"
                          v-else-if="item1.identity == 'guest'"
                        >
                          <img
                            src="../../../../static/images/icon/i_identity9.png"
                            class="room_status"
                          />
                        </el-button>
                        <el-button
                          size="mini"
                          title="无人"
                          v-else-if="item1.identity == 'noPeople'"
                        >
                          <img
                            src="../../../../static/images/icon/i_identity0.png"
                            class="room_status"
                          />
                        </el-button>
                        <el-button size="mini" title="暂无数据" v-else
                          >暂无数据</el-button
                        >
                        <div class="temperature">
                          <span class="room_temperature">{{
                            item1.temperature
                              ? parseInt(item1.temperature)
                              : "--"
                          }}</span>
                          <span class="room_unit">℃</span>
                        </div>
                      </div>
                      <div class="room_icon" v-show="item1.basicstatus == 'on'">
                        <el-button
                          size="mini"
                          title="请勿打扰"
                          v-if="item1.dont == 'true'"
                        >
                          <img src="../../../../static/images/icon/i_dnd.png" />
                        </el-button>
                        <el-button
                          size="mini"
                          title="请即清理"
                          v-if="item1.clear == 'true'"
                        >
                          <img
                            src="../../../../static/images/icon/clear_room.png"
                          />
                        </el-button>
                        <!-- <el-button size="mini" title="正在清理" v-if="item1.status == 'QINGLIING'">
                      <img src="../../../../static/images/icon/clear_room.png">
                        </el-button>
                        <el-button size="mini" title="等待检查" v-if="item1.status == 'JIANCHA'">
                          <img src="../../../../static/images/icon/clear_room.png">
                        </el-button>
                        <el-button size="mini" title="正在检查" v-if="item1.status == 'JIANCHAING'">
                      <img src="../../../../static/images/icon/clear_room.png">
                      </el-button>-->
                        <el-button
                          size="mini"
                          title="退房"
                          v-if="item1.checkout == 'true'"
                        >
                          <img
                            src="../../../../static/images/icon/i_checkout.png"
                          />
                        </el-button>
                        <el-button
                          size="mini"
                          title="空调(关)"
                          v-if="item1.realOnOff === 'off'"
                        >
                          <img
                            src="../../../../static/images/icon/i_speed0.png"
                          />
                        </el-button>
                        <span
                          v-if="item1.realOnOff === 'on'"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                          "
                        >
                          <el-button
                            size="mini"
                            title="风速(关)"
                            v-if="item1.realSpeed == 'stop'"
                          >
                            <img
                              src="../../../../static/images/icon/i_speed0.png"
                            />
                          </el-button>
                          <el-button
                            size="mini"
                            title="风速(低)"
                            v-if="item1.realSpeed == 'low'"
                          >
                            <img
                              src="../../../../static/images/icon/i_speed1.png"
                            />
                          </el-button>
                          <el-button
                            size="mini"
                            title="风速(中)"
                            v-else-if="item1.realSpeed == 'middle'"
                          >
                            <img
                              src="../../../../static/images/icon/i_speed2.png"
                            />
                          </el-button>
                          <el-button
                            size="mini"
                            title="风速(高)"
                            v-else-if="item1.realSpeed == 'high'"
                          >
                            <img
                              src="../../../../static/images/icon/i_speed3.png"
                            />
                          </el-button>
                          <el-button
                            size="mini"
                            title="风速(自动)"
                            v-else-if="item1.realSpeed == 'auto'"
                          >
                            <img
                              src="../../../../static/images/icon/i_speed_auto.png"
                            />
                          </el-button>
                        </span>

                        <el-button
                          size="mini"
                          title="门磁报警"
                          v-if="item1.doorWarning == 'true'"
                        >
                          <img
                            src="../../../../static/images/icon/i_doorwarning.png"
                          />
                        </el-button>
                      </div>
                    </div>
                    <!-- <div v-show="item1.basicstatus != 'on'" class="unconnected">
           <el-button size="mini" title="未链接">
            <img src="../../../../static/images/icon/link.png" />
           </el-button>
          </div> -->
                  </div>
                </el-card>
              </el-collapse-item>
              <span class="notData" v-if="!tableData || tableData.length == 0"
                >暂无数据</span
              >
            </el-collapse>
          </el-card>
        </el-row>
      </el-col>
      <!-- ?:xs="4" :sm="4" :md="4" :lg="3" :xl="2"  -->
      <el-col>
        <div class="indexCard">
          <div class="fixed" :class="{ leftFlxed: collapse }">
            <!-- v-if="noticeList && noticeList.length" -->
            <el-card
              shadow="always"
              class="messageBar"
              :body-style="{ height: 'calc(100% - 150px)', overflow: 'hidden' }"
            >
              <div slot="header" class="titleCenter">
                <span class="t" @click="openMsg()">消息</span>
                <div class="t_content">
                  <div class="Statistics" @click="openMsg('SOS')">
                    <span>SOS</span>
                    <span class="total">{{ roomNum.sosTotal }}</span>
                  </div>
                  <div class="Statistics" @click="openMsg('门牌离线')">
                    <span>门牌离线</span>
                    <span class="total">{{ roomNum.faceOnline }}</span>
                  </div>
                  <div class="Statistics" @click="openMsg('清理操作')">
                    <span>清理操作</span>
                    <span class="total">{{ roomNum.qingliTotal }}</span>
                  </div>

                  <div class="Statistics" @click="openMsg('门磁报警')">
                    <span>门磁报警</span>
                    <span class="total">{{ roomNum.menciTotal }}</span>
                  </div>
                </div>
              </div>
              <!-- <div style="height:100%"> -->
              <!-- <div slot="body" > -->
              <!-- <el-scrollbar > -->
              <div class="scrollbarWrap">
                <ul class="MsgList">
                  <li
                    v-for="(item, index) in noticeList"
                    :key="index"
                    @click="toFloorId(item.floorId)"
                  >
                    <el-tooltip
                      :disabled="
                        !(item.buildingName && item.buildingName.length > 11)
                      "
                      placement="top"
                      :content="item.buildingName"
                    >
                      <div class="buildingName">{{ item.buildingName }}</div>
                    </el-tooltip>
                    <div>
                      <span class="roomNum">{{ item.roomNumber }}</span>
                      <span
                        class="roomStatusName"
                        :class="{
                          isSOS: item.status == 'SOS',
                          isQingLi: item.status == '清理操作',
                          isMenCi: item.status == '门磁报警',
                          isOff: item.status == '门牌离线',
                        }"
                        >{{ item.status }}</span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <!-- </el-scrollbar> -->
              <!-- </div> -->
            </el-card>
            <el-card shadow="always" class="topCard">
              <div slot="header" class="titleCenter">
                <span class="t">图示</span>
              </div>
              <div>
                <ul class="iconList">
                  <li v-for="(i, key) in iconList" :key="key" class="icon1">
                    <div class="iconName">
                      <img :src="i.icon" :alt="i.name" />
                      <span>{{ i.name }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </div>
      </el-col>
    </el-row>

    <RoomOperation :roomInfo="roomInfo" ref="operation" />
    <messageDialog ref="msgBar" :tablist="noticeList" :roomNum="roomNum" />
    <!-- <audio id="audio" controls hidden="true">
   <source src="../../../../static/audio/sos.mp3" type="audio/mpeg" />
  </audio> -->
    <!-- <p class="tips-text" v-html="archivalInformation">  </p> -->
  </div>
</template>
<script>
import {
  getRoomStatusList,
  getFloorSelect,
  getHotelRoomType,
  getBuildingSelect,
  getHotelSelect,
  getBrandSelect,
} from "@/api";
import RoomOperation from "../component/RoomOperation.vue";
import messageDialog from "../component/messageDialog.vue";

export default {
  name: "kfjk",
  components: {
    RoomOperation,
    messageDialog,
  },
  data() {
    return {
      query: {},
      foldData: false,
      roomInfo: {
        roomTypeName: null,
      },
      totalRoomNum: 0,
      totalRoomOfflineNum: 0,
      myInterval: null, //定时器
      audioInterval: null,
      tip: true, // 语音提示打开
      voiceTip: "SOS警告提示", //语音提示信息
      SOSList: [], //有异常信息
      noticeList: [],
      loading: false,
      // 建筑列表数据
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      tableData: null,
      // 查询的数据
      seekData: {
        buildingId: null,
        floorId: null,
        roomNumber: null,
        roomTypeId: null,
        hotelId: null,
        brandId: null,
        basicstatus: null,
        temStatus: null,
      },

      allSelectData: [],
      // 下拉框的数据
      brandSelectData: [],
      hotelSelectData: [],
      buildingSelectData: [],
      floorSelectData: null,
      roomTypeSelectData: null,
      rentSelectData: [
        {
          label: "待租",
          value: "vacant",
        },
        {
          label: "出租",
          value: "rented",
        },
      ],
      // 识别身份
      identitySelectData: [
        {
          value: "president",
          label: "总裁卡",
        },
        {
          value: "manage",
          label: "管理卡",
        },
        {
          value: "service",
          label: "服务员卡",
        },
        {
          value: "guest",
          label: "客人卡",
        },
        {
          value: "noPeople",
          label: "无卡",
        },
      ],
      RTSelectData: [
        {
          value: "mur",
          label: "请求清理",
        },
        {
          value: "dnd",
          label: "请勿打扰",
        },
      ],

      mcSelectData: [
        {
          value: "false",
          label: "正常",
        },
        {
          value: "true",
          label: "异常",
        },
      ],
      sosSelectData: [
        {
          value: "true",
          label: "有",
        },
        {
          value: "false",
          label: "无",
        },
      ],
      outSelectData: [
        {
          value: "true",
          label: "有",
        },
        {
          value: "false",
          label: "无",
        },
      ],
      temperatureSelectData: [
        {
          value: "true",
          label: "正常",
        },
        {
          value: "false",
          label: "异常",
        },
      ],
      isConnectData: [
        {
          value: "on",
          label: "连接",
        },
        {
          value: "off",
          label: "未连接",
        },
      ],
    };
  },
  // 注册表格组件
  watch: {
    tip: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.audioInterval = window.setInterval(() => {
            setTimeout(() => {
              // this.$store.commit('IS_PLAY_VOICE', true)
              this.tip = true;
            }, 1);
          }, 180000);
        } else {
          if (this.audioInterval) {
            clearInterval(this.audioInterval);
          }
        }
      },
    },
  },

  created() {
    //this.polling("init");
    this.initList(); //带定时器的方法
    this.selecterInit();
    this.userJurisdiction = this.$store.state.roleMenu;
  },

  methods: {
    selecterInit() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
          this.seekData.brandId = this.brandSelectData[0].id;
          this.selectOnes(this.seekData.brandId, "search");
        }
      });
    },
    // 选择框
    selectOnes(id, type) {
      ["hotelId", "buildingId", "floorId"].forEach(
        (key) => (this.seekData[key] = null)
      );
      this.initHotel();

      if (!id) {
        return;
      }

      // 房间类型下拉框
      getHotelSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.hotelSelectData = res.data.data;
          this.seekData.hotelId = this.hotelSelectData[0].id;
          this.selectHotel(this.seekData.hotelId);
          if (type) this.handleSearch("seekData");
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
    },
    selectHotel(id) {
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      if (!id) return;
      getBuildingSelect(id).then((res) => {
        this.buildingSelectData = res.data.data;
      });

      // 房间类型下拉框
      getHotelRoomType(id).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
        }
      });
    },
    // 获取楼层下拉框
    selectBuilding(id) {
      this.seekData.floorId = null;
      this.floorSelectData = null;
      if (!id) return;
      getFloorSelect(id).then((res) => {
        this.floorSelectData = res.data.data;
      });
    },
    initHotel() {
      this.buildingSelectData = null;
      this.floorSelectData = null;
      this.roomTypeSelectData = null;
    },
    openMsg(text = "") {
      let list = JSON.parse(JSON.stringify(this.noticeList));
      this.$refs.msgBar.penDialog(list, text);
    },
    toFloorId(id) {
      let theFloor = document.getElementById(`floorId${id}`);
      theFloor.scrollIntoView();
    },
    play() {
      let audio = document.getElementById("audio");
      // 未播放
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
      }
    },
    stop() {
      let audio = document.getElementById("audio");
      // 已播放
      // this.tip = false
      if (this.tip && !audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    },

    closeTips() {
      this.tip = false;
      let audio = document.getElementById("audio");
      audio.pause();
    },

    speckText(str) {
      let audio = document.getElementById("audio");
      audio.loop = true;
      audio.defaultPlaybackRate = 1.5;
      audio.load();
      // this.tip = true
      if (this.tip) {
        if (audio.paused) {
          setTimeout(() => {
            audio.play();
          }, 1500);
          // audio.play();
        } else {
          audio.pause();
        }
      }
    },

    handleChange(val) {
      // this.activeNames = JSON.parse(sessionStorage.getItem('allId'))
    },

    initList() {
      this.myInterval = window.setInterval(() => {
        setTimeout(() => {
          this.polling();
        }, 1);
      }, 10 * 1000);
    },
    rowList(res, type) {
      if (type) {
        if (res.data.data.dropdown && res.data.data.dropdown.length) {
          this.allSelectData = res.data.data.dropdown;
          this.defaultData();
        }
      }
      let allId = [];
      if (res.data.data.rows.length) {
        res.data.data.rows.forEach((item, index) => {
          if (item.roomStatusRealtimeList.length) {
            item.roomStatusRealtimeList = this.sortFunction(
              item.roomStatusRealtimeList
            );
          }
          if (type) allId.push(index);
        });
        if (type) this.activeNames = allId;
      }
      let allRoomMsg = [];
      let SOSList = [];
      if (res.data.data.roomMsg.length) {
        res.data.data.roomMsg.forEach((item) => {
          if (item.status) {
            allRoomMsg.push(item);
          }
          //  ||item.status == "门牌离线"
          if (item.status == "SOS") {
            SOSList.push(item);
          }
        });
      }
      let copySosArr = JSON.parse(sessionStorage.getItem("sos"));
      if (copySosArr) {
        let newSosArr = JSON.parse(JSON.stringify(copySosArr));
        if (
          JSON.parse(sessionStorage.getItem("sos")).length !== SOSList.length
        ) {
          this.tip = true;
        } else {
          SOSList.forEach((item) => {
            newSosArr.forEach((item1, index) => {
              if (item.roomNumber === item1.roomNumber) {
                newSosArr.splice(index, 1);
              }
            });
          });
        }
        if (newSosArr.length) {
          this.tip = true;
        }
      }
      if (SOSList.length) {
        this.speckText(this.voiceTip);
      } else {
        this.stop();
      }
      sessionStorage.setItem("sos", JSON.stringify(SOSList));
      this.noticeList = allRoomMsg;
      this.tableData = res.data.data.rows;

      // 统计客房数和离线数
      if (this.tableData) {

        let totalRoomOfflineNum = 0;
        let totalRoomNum = 0;

        this.tableData.forEach((floor, index) => {
          let floorRoomOnlineNum = 0;
          let floorRoomOfflineNum = 0;

          if (floor.roomStatusRealtimeList) {
            floor.roomStatusRealtimeList.forEach((room, index) => {
                if (room.basicstatus === 'on') {
                  floorRoomOnlineNum++;
                } else {
                  floorRoomOfflineNum++;
                }

                floor.floorRoomOnlineNum = floorRoomOnlineNum;
                floor.floorRoomOfflineNum = floorRoomOfflineNum;
            });

            totalRoomNum += floor.roomStatusRealtimeList.length;
            totalRoomOfflineNum += floorRoomOfflineNum;

          }

        });

        this.totalRoomNum = totalRoomNum;
        this.totalRoomOfflineNum = totalRoomOfflineNum;

      }
      

    },
    // 初始化表格数据
    polling(type) {
      if (this.seekData.roomNumber != null) {
        this.seekData.roomNumber = parseInt(this.seekData.roomNumber);
      }
      this.query = Object.assign({}, this.seekData);
      getRoomStatusList(this.query)
        .then((res) => {
          if (res.status === 200) {
            this.rowList(res, type);
          }
        })
        .catch((err) => {});
    },

    // 默认品牌、门店下拉数据
    defaultData() {
      this.brandSelectData = this.getSelectData(this.allSelectData, 0);
      if (this.brandSelectData.length) {
        if (!this.seekData.brandId) {
          this.seekData.brandId = this.brandSelectData[0].id;
        }
      }
      this.hotelSelectData = this.getSelectData(
        this.allSelectData,
        1,
        this.seekData.brandId
      );
      if (this.hotelSelectData.length) {
        if (!this.seekData.hotelId) {
          this.seekData.hotelId = this.hotelSelectData[0].id;

          this.getRoomType(this.seekData.brandId);

          this.selectTwo(this.seekData.hotelId, true);
        }
      }
    },
    getSelectData(arr, level, id) {
      let result = [];
      // 品牌
      this.allSelectData.forEach((item, index) => {
        if (level == 0) {
          result.push({ brandName: item.name, id: item.id });
        }
        if (id != undefined) {
          // 门店
          if (item.word.length) {
            if (level == 1 && id == item.id) {
              result = item.word;
            } else if (level == 2) {
              item.word.forEach((item1, index1) => {
                console.log(item1);
                if (item1.id == id) {
                  // 建筑
                  item1.word.forEach((item2, index2) => {
                    result.push({
                      buildingName: item2.name,
                      id: item2.id,
                    });
                  });
                }
              });
            }
          }
        }
      });
      return result;
    },

    sortFunction(itemList) {
      // 大写字母开头
      var regUpper = /^[A-Za-z]+$/;
      var upperArr = itemList.filter((ele, index) => {
        return ele.roomNumber && regUpper.test(ele.roomNumber.substr(0, 1));
      });
      // 大写字母开头排序
      upperArr = upperArr.sort((a, b) => {
        return a.roomNumber.localeCompare(b.roomNumber);
      });
      // 汉字开头
      var reg = new RegExp("^[\u4e00-\u9fa5]");
      var wordArr = itemList.filter((ele, index) => {
        return ele.roomNumber && reg.test(ele.roomNumber.substr(0, 1));
      });
      // 汉字开头排序
      wordArr = wordArr.sort((a, b) => {
        return a.roomNumber.localeCompare(b.roomNumber);
      });

      let arr = wordArr.concat(upperArr, itemList);
      return Array.from(new Set(arr));
    },

    // 获取门店下拉框 -- 2
    selectOne(id) {
      this.seekData.hotelId =
        this.seekData.buildingId =
        this.seekData.floorId =
        this.seekData.roomTypeId =
        this.seekData.roomNumber =
          null;
      this.hotelSelectData =
        this.buildingSelectData =
        this.floorSelectData =
          null;
      this.hotelSelectData = this.getSelectData(
        this.allSelectData,
        1,
        this.seekData.brandId
      );
      if (this.hotelSelectData.length) {
        this.seekData.hotelId = this.hotelSelectData[0].id;
        this.selectTwo(this.seekData.hotelId, true);
      }
      // 获取房间类型下拉框
      getHotelRoomType(this.seekData.hotelId).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
        }
      });
    },
    getRoomType(id) {
      getHotelRoomType(id).then((res) => {
        if (res.status === 200) {
          this.roomTypeSelectData = res.data.data;
        }
      });
    },
    // 获取建筑下拉框  -- 3
    selectTwo(id, show) {
      if (!id) {
        return;
      }
      this.seekData.buildingId =
        this.seekData.floorId =
        this.seekData.roomNumber =
          null;
      this.buildingSelectData = this.floorSelectData = null;
      getBuildingSelect(id).then((res) => {
        if (res.data.data.length > 0) {
          this.buildingSelectData = res.data.data;
          //  if (!show) {
          // this.seekData.buildingId = res.data.data[0].id;
          // this.selectThree(this.seekData.buildingId);
          //  }
        } else {
          this.$message({
            message: "该门店下没有建筑数据",
            type: "warning",
          });
        }
      });
    },
    // 获取楼层下拉框 -- 4
    selectThree(id) {
      getFloorSelect(id).then((res) => {
        if (res.data.data.length > 0) {
          this.floorSelectData = res.data.data;
        } else {
          this.$message({
            message: "该建筑下没有楼层数据",
            type: "warning",
          });
        }
      });
    },

    // 查询按钮
    handleSearch() {
      this.polling("init");
    },
    // 重置按钮
    reset() {
      this.$refs.seekData.resetFields();
    },

    openDialog(val) {
      if (
        this.equipmenlook &&
        val.basicstatus == "on" &&
        val.hotelUsable != "off"
      ) {
        this.roomInfo = val;
        // console.log(this.roomInfo);
        // this.getRoomDeviceInfo(val.mainboardId);
        this.$refs.operation.getRoomDeviceInfo(val.mainboardId);
      } else {
        if (!this.equipmenlook) {
          this.$message({
            type: "error",
            message: "暂无权限，无法查看！",
          });
        } else if (val.basicstatus != "on") {
          this.$message({
            type: "error",
            message: "设备未连接",
          });
        } else if (val.hotelUsable == "off") {
          this.$message({
            type: "error",
            message: "设备已被停用",
          });
        }
      }
    },
  },
  computed: {
    iconList() {
      return this.$store.state.iconList;
    },
    ckList() {
      return this.$store.state.ckList;
    },
    equipmenlook() {
      return this.userJurisdiction.indexOf("equipmentInfo:look") != -1;
    },
    archivalInformation() {
      return this.$store.state.archivalInformation;
    },
    airBtn: function () {
      return this.userJurisdiction.indexOf("air:btn");
    },
    mqttAdd: function () {
      return this.userJurisdiction.indexOf("mqtt:add");
    },
    collapse() {
      return this.$store.state.collapse;
    },
    roomNum() {
      let obj = {
        sosTotal: 0,
        menciTotal: 0,
        qingliTotal: 0,
        faceOnline: 0,
      };
      this.noticeList.forEach((item) => {
        if (item.status == "SOS") {
          obj.sosTotal++;
        } else if (item.status == "门牌离线") {
          obj.faceOnline++;
        } else if (item.status == "清理操作") {
          obj.qingliTotal++;
        } else if (item.status == "门磁报警") {
          obj.menciTotal++;
        }
      });
      return obj;
    },
  },

  destroyed() {
    clearInterval(this.myInterval);
    clearInterval(this.audioInterval);
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  margin: 0 5px;
}

.tips-text {
  text-align: center;
  padding: 30px;
}
.my-box {
  .w2 {
    width: 134px !important;
  }

  .always-shadow {
    width: 127px;
    box-shadow: 5px 5px 5px -1px #eee;
    height: 118px;
    margin: 5px;
    border: 2px solid #f4f4f4;
    float: left;
    .building {
      img {
        margin-right: 5px;
      }
    }
    .room_item {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      .room_num {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        span {
          display: inline-block;
          padding: 0 18px 0 5px;
          height: 26px;
          // width: 33%;
          line-height: 26px;
          color: #fff;
          background: #409eff;
          font-size: 10px;
          font-weight: bold;
          position: relative;
          &:before {
            position: absolute;
            right: 0;
            top: 0;
            content: "";
            border-width: 13px 13px 13px 13px;
            border-style: solid;
            border-color: transparent #fff transparent transparent;
          }
        }
        .sosImgBar {
          height: 26px;
          position: relative;
        }
        .sosImg {
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
        }
      }
      .room_info {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 5px 0;
        .room_status {
          width: 30px;
          height: 30px;
        }
        .temperature {
          .room_temperature {
            font-size: 30px;
            color: #409eff;
            margin-right: 2px;
          }
        }
      }
      .room_icon {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .unconnected {
        text-align: center;
        margin-top: 6px;
      }
    }
  }
  .rentBackground {
    border: 2px solid #adfbbf;
    background-color: #adfbbf;
    .room_num {
      span {
        &:before {
          border-color: transparent #adfbbf transparent transparent !important;
        }
      }
    }
    // border: 2px solid #45ff40;
  }
  .noBackground {
    background-color: #fff;
    border: 2px solid #e9e9e9;
  }
  .rentBackground:hover,
  .noBackground:hover {
    border: 2px solid #409eff;
  }

  .notData {
    display: inline-block;
    width: 100%;
    height: 120px;
    text-align: center;
    line-height: 120px;
    color: #606266;
  }

  .el-row {
    // margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .reset-button {
    white-space: nowrap;
  }
}

.homeContent {
  // padding-left: 200px;
  padding-right: 160px;
}
.maxH {
  min-height: 75vh;
}
.fixed {
  width: 160px;
  height: auto;
  position: fixed;
  top: 110px;
  // left: 130px;
  right: 17px;
  bottom: 5px;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .topCard {
    height: 250px !important;
  }
  .messageBar {
    flex: 1;
    margin-bottom: 20px;
  }
}
.titleCenter {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  .t {
  }
  .t_content {
    font-size: 14px;
    .Statistics {
      padding-top: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    .total {
      text-align: right;
      display: inline-block;
      // width: 18px;
      min-width: 12px;
      padding: 3px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid #000;
      // font-weight: 600;
      // background-color: #f56c6c;
      // color: #fff;
      text-align: center;
      line-height: 12px;
      font-size: 12px;
    }
  }
}
.indexCard .buildingName {
  width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.indexCard .roomNum {
  width: 50px;
  display: inline-block;
  cursor: pointer;
}
.roomStatusName {
  font-weight: 600;
}
.isSOS {
  color: #ff4d4d;
}
.isQingLi {
  color: #409eff;
}
.isMenCi {
  color: #e6a23c;
}
.indexCard .leftFlxed {
  // left: 65px ;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
}

.iconList {
  font-size: 12px;
  color: #606266;
  //  max-height: 45vh;
  // height: calc(100% - 570px);
  li {
    padding: 5px 0;
    overflow: hidden;
  }
}
.MsgList {
  font-size: 12px;
  padding-bottom: 10px;
  color: #606266;
  // height: calc(100% - 300px);
  li {
    padding: 5px 0;
  }
}
.icon1 {
  display: inline-block;
  width: 50%;

  .iconName {
    white-space: nowrap;
    display: flex;
    align-items: center;
    img {
      width: 12px;
      vertical-align: middle;
    }
    span {
      padding-left: 2px;
      white-space: nowrap;
    }
  }
}
</style>

<style type="text/css">
.roomPage .el-card__body {
  padding: 5px;
}
.room_icon .el-button--mini,
.room_info .el-button--mini,
.unconnected .el-button--mini {
  padding: 0px;
  margin: 0px 3px;
  border: none;
}
.room_icon .el-button--mini:hover,
.room_info .el-button--mini:hover,
.unconnected .el-button--mini:hover {
  background: none;
}
.setting .el-input__inner {
  width: 40px;
  padding: 0 5px;
}

.air_info .el-input-number .el-input__inner {
  font-size: 20px;
}

.building-info .el-button {
  background: none;
}

.top-statics {
  background-color: #FFF;
  padding: 10px;
  margin: 6px;
  border-radius: 4px;
}

.top-statics .badge {
  margin-right: 25px;
}

.room-num {
  margin: 2px 0 0 20px;
}

.room-num .badge {
  margin: 6px 0 0 4px;
}

.room-offline {
  margin: 2px 0 0 10px;
}

.room-offline .badge {
  margin: 6px 0 0 4px;
}

</style>
