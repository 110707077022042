<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" :visible.sync="show" width="60%" class="roomManagementDialog">
   <el-row :gutter="20" class="topTab">
     <el-col :span="4">
     <div class="Statistics" :class="{active:!text}" @click="changeText()">
      <span class="name">全部</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='SOS'}" @click="changeText('SOS')">
      <span class="name">SOS</span>
      <span class="total">{{ roomNum.sosTotal }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='门牌离线'}" @click="changeText('门牌离线')">
      <span class="name">门牌离线</span>
      <span class="total">{{ roomNum.faceOnline }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='清理操作'}" @click="changeText('清理操作')">
      <span class="name">清理操作</span>
      <span class="total">{{ roomNum.qingliTotal }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='门磁报警'}" @click="changeText('门磁报警')">
      <span class="name">门磁报警</span>
      <span class="total">{{ roomNum.menciTotal }}</span>
     </div>
    </el-col>
   </el-row>
   <div>
    <el-table ref="multipleTable" :data="list" @row-click="changeMsg" tooltip-effect="dark" height="500" style="width: 100%">
     <el-table-column prop="buildingName" label="建筑"></el-table-column>
     <el-table-column prop="floorName" label="楼层"></el-table-column>
     <el-table-column prop="roomNumber" label="房间号"></el-table-column>
     <el-table-column prop="status" label="消息类型">
       <template slot-scope="scope">
         <div>
            <span class="roomStatusName" :class="{
              isSOS: scope.row.status == 'SOS',
              isQingLi: scope.row.status == '清理操作',
              isMenCi: scope.row.status == '门磁报警',
              isOff:scope.row.status == '门牌离线'
                          }">{{ scope.row.status }}</span>
         </div>
       </template>
     </el-table-column>
    </el-table>
   </div>
  </el-dialog>

 </div>
</template>
<script>
export default {
 props: {
  tablist: {
   default: [],
   type: Array,
  },
  roomNum: {
   type: Object,
  },
 },
 data() {
  return {
   show: false,
   //  tablist: [],
   text: "",
  };
 },
 computed: {
  list() {
   if (this.text) {
    return this.tablist.filter((item) => {
     return item.status == this.text;
    });
   } else {
    return this.tablist.filter((item) => {
     return item.status;
    });
   }
  },
 },
 methods: {
   changeMsg(row){
     this.$parent.toFloorId(row.floorId);
   },
  changeText(text = "") {
   this.text = text;
   setTimeout(() => {
    console.log(this.list, "askfjhsdf");
   }, 50);
  },
  penDialog(list = [], text = "") {
   this.show = true;
   this.text = text;
  },
 },
};
</script>


<style lang="scss" scoped>
.topTab{
  border-bottom: 3px solid rgb(80, 80, 80);
  padding-bottom: 20px;
  margin-bottom: 10px;
}


</style>